* {
  box-sizing: border-box;
}
body,
html,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.HtmlControls {
  position: fixed;
  z-index: 1;
  background-color: #fafafa;
  margin: 50px;
  padding: 20px;
  border-radius: 5px;
  border: 2px solid #ddd;
  box-shadow: 0 5px 10px -5px #0005;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 1.5;
}
.HtmlControls label {
  display: inline-block;
  width: 60px;
  text-align: right;
  padding-right: 5px;
  opacity: 0.5;
  transform: translateY(-3px);
}
